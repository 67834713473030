import { ReactNode } from 'react';
import { createClient, fetchExchange, Provider } from 'urql';


export const client = createClient({
  url: '/api-be/graphql',
  exchanges: [fetchExchange],
  fetchOptions: {
    credentials: 'include'
  }
});


export function GraphqlProvider({ children }: { children: ReactNode }) {
  return <Provider value={client}>{children}</Provider>;
}
